import React from "react"
import { StaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import styled from "styled-components"

const BeautyPageBanner = ({ className, children }) => (
  <StaticQuery
    query={graphql`
      query {
        bgImage: file(relativePath: {eq: "beautyBg.jpg"}) {
          childImageSharp {
            fluid(maxWidth: 1400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => {
    const imageData = data.bgImage.childImageSharp.fluid
    return (
      <BackgroundImage Tag="section" className={className} fluid={imageData}>
        {children}
      </BackgroundImage>
      )
    }}
  />
)

const StyledBeautyPageBanner = styled(BeautyPageBanner)`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 400px;
  padding: 0 10rem;
  @media (max-width: 469px) {
    height: 400px;
  }
  @media (max-width: 769px) {
    padding: 0 5rem;
  }
  @media (max-width: 469px) {
    padding: 0 1rem;
  }
`;

export default StyledBeautyPageBanner